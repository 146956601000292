let footer = document.querySelector('footer');
let headings = footer.querySelectorAll('.heading');
headings.forEach(heading => {
    heading.addEventListener('click', () => {
        heading.parentElement.classList.toggle('active');
    });
});

window.addEventListener('scroll', function() {
    var scrollIndicator = document.querySelector('.scroll-down');
    if (window.scrollY > 200) {
        scrollIndicator.style.transition = 'opacity 0.5s ease-in-out';
        scrollIndicator.style.opacity = '0';
        setTimeout(function() {
            scrollIndicator.style.display = 'none';
        }, 500);
    } else {
        scrollIndicator.style.display = 'block';
        setTimeout(function() {
            scrollIndicator.style.display = 'block';
            scrollIndicator.style.transition = 'opacity 0.5s ease-in-out';
            scrollIndicator.style.opacity = '1';
        }, 500); // Delay to ensure display change is noticed by the browser
    }
});

