let header = document.querySelector('header');
let burger = document.querySelector('.mobile-burger');
if (typeof(document.querySelector('.mobile-menu .menu')) != 'undefined' && document.querySelector('.mobile-menu .menu') != null) {
    let initialSubHeight = document.querySelector('.mobile-menu .menu').offsetHeight;

}
let mobileMenu = document.querySelector('.mobile-menu');
let mobileMenuBack = document.querySelector('.mobile-menu .back-button');
let mobileMenuItems = document.querySelectorAll('.mobile-menu .menu-item-has-children');
let searchBtn = header.querySelector('.search-btn');
let searchInput = header.querySelector('.header-search');
burger.addEventListener('click', () => {
    mobileMenu.classList.toggle('active');
    header.classList.toggle('mobile-menu-active');
    header.classList.toggle('fixed');
    header.classList.remove('white');
    document.body.classList.toggle('fixed');
});
if (typeof(document.querySelector('.mobile-menu .menu')) != 'undefined' && document.querySelector('.mobile-menu .menu') != null) {
    document.querySelector('.mobile-menu .menu').style.height = document.querySelector('.mobile-menu .menu').offsetHeight + 'px';
}
mobileMenuItems.forEach(item => {
    item.addEventListener('click', (event) => {
        const clickedItem = event.target;
        if (clickedItem.classList.contains('menu-item-has-children') || clickedItem.getAttribute('href') === '#') {
            event.preventDefault();
            mobileMenu.classList.add('submenu');
            // setTimeout(() => {
            mobileMenu.classList.add('absolute');
            // }, 500);
            item.classList.add('active');
            item.querySelector('a').classList.add('show');
            item.querySelector('.sub-menu').classList.add('show');
            document.querySelector('.mobile-menu .menu').style.height = (item.querySelector('.sub-menu').offsetHeight + 100) + 'px';
        }
    });
});
mobileMenuBack.addEventListener('click', () => {
    mobileMenuItems.forEach(item => {
        item.classList.remove('active');
        item.querySelector('a').classList.remove('show');
        item.querySelector('.sub-menu').classList.remove('show');
    });
    mobileMenu.classList.remove('absolute');
    mobileMenu.classList.remove('submenu');
    document.querySelector('.mobile-menu .menu').style.height = initialSubHeight + 'px';
});

let prevScrollpos = window.pageYOffset;
const scrollDownTrigger = 20;

window.onscroll = () => {
    let currentScrollPos = window.pageYOffset;

    if (prevScrollpos >= currentScrollPos) {
        if (currentScrollPos < scrollDownTrigger) {
            if (!header.classList.contains('white-initially')) {
                header.classList.remove('white');
            }
        } else {
            header.classList.add('white');
        }
    } else {
        if (currentScrollPos > scrollDownTrigger) {
            header.classList.add('hidden');
        }
    }
    if (currentScrollPos < prevScrollpos) {
        if (!header.classList.contains('no-follow')) {
            header.classList.remove('hidden');
        }
    }
    prevScrollpos = currentScrollPos;
}

/**
 * Toggle search-mode class in order
 * to open/close search bar on header.
 */
searchBtn.addEventListener('click', (e) => {
    if (window.innerWidth > 991) {
        e.preventDefault();
        header.classList.toggle('search-mode');
    }

    if (header.classList.contains('search-mode')) {
        searchInput.value = '';
        searchInput.parentElement.classList.remove('active');
    }
});

/**
 * Add/Remove .active class depeding on input character length.
 */
searchInput.addEventListener('input', function (e) {
    if (this.value.length) {
        searchInput.parentElement.classList.add('active');
    } else {
        searchInput.parentElement.classList.remove('active');
    }
});

/**
 * Remove search-mode class
 * if click has been made outside of the header.
 */
document.addEventListener('click', (e) => {
    if (!header.contains(e.target)) {
        header.classList.remove('search-mode');
    }
});

/**
 * Remove search-mode class
 * on ESC key press.
 */
document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
        header.classList.remove('search-mode');
    }
});
